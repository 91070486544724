@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://api.fonts.coollabs.io/css2?family=Autour+One&family=Lato:wght@300;400&display=swap");
/* @import "./characterMenu.css"; */
/* @import "./themes/standard.css"; */

/*****************************************************
Layout
*****************************************************/

html {
  /* background-image: linear-gradient(180deg, #f6d365 0%, #e98836 100%); */
  background-attachment: fixed;
  /* background-image: url("../funky-lines.png"); */
  
  font-family: "Lato", sans-serif;
  @apply text-neutral-darkest dark:text-white bg-light dark:bg-dark;
}

h1,h2,h3,h4,h5,h6 {
  font-family: "Autour One", serif;
  /* @apply text-neutral-darker dark:text-neutral; */
}

h1 {@apply text-3xl;}
h2 {@apply text-2xl;}
h3 {@apply text-xl;}

.highlighted-text {

}

header, .header, button,
nav li,
label,
.cta, .control-selected, .control-overlay.selected{
  @apply font-bold;
}
header {
  @apply pt-2 pb-2;
}

header, .header,
.nav-inline ul,
.features-list, .menu-page.selected,.header-title, main,
main>section,
.creator-menu,
.creator-view, #portrait-container, .control-selected, .cta, .control-container fieldset ul, .cta-buttons, .palette-colours, .palette-meta,
.palette-actions, .palette-meta label, .creator-container, .creator-view, .accordion-header, datalist, .control-overlay.selected, [role="tab"] {
  @apply flex;
}

header, main,
main>section:not(.cta-panel):not(.creator-container),
.creator-menu,
.creator-view, #portrait-container, .cta-buttons-col, .palette-meta,
.palette-actions, .palette-meta label, .creator-view {
  @apply flex-col
}

.menu-page.selected,
.header,
.nav-inline ul,
.features-list, .cta, .cta-panel, .cta-buttons-row, .control-container fieldset ul, .palette-colours, .accordion-header {
  @apply flex-row;
}

header {
  @apply sm:flex-row;
}

.header, .nav-inline ul, .features-list, .control-container fieldset ul {
  @apply flex-wrap;
}

.button-toggle input {
  @apply hidden
}

header,
main, .creator-view, .creator-menu, #portrait-container, .menu-description, .cta-text, .creator-view, datalist, .slider, .feature-panel>h2 {
  @apply w-full 
}

/* .control-container ul,
.features-list,
.cta-panel {
  @apply grid justify-items-center sm:flex sm:flex-row sm:flex-wrap;
} */

header,
.header, .header-title, main>section:not(.creator-container), .control-selected, .cta, .cta-buttons, .palette-colours, #portrait-container, .control-overlay.selected, .nav-inline ul {
  @apply items-center;
}

header,
.header, main>section, .palette-colours, .accordion-header, datalist, .cta-panel {
  @apply justify-between;
}

#portrait-container, .control-selected, .control-container ul,
.features-list, .cta, .control-overlay.selected {
  @apply justify-center;
}

li, .actions button, .actions label, .accordion-header {
  @apply border-2 border-solid border-transparent;
}

.control-container .pagination ul, .feature-card {
  @apply text-center;
}

header, .panel {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  @apply  bg-neutral-light dark:bg-neutral-darker;
}

header nav ul {
  @apply space-x-4 pr-4;
}

header, main {
  @apply md:pl-16 md:pr-16;
}

.header-icon {
  @apply text-3xl ml-2;
}

.header-title {
  @apply sm:pl-2;
}

.nav-inline ul:not(.pagination ul) {
  @apply justify-evenly;
}

main {
  @apply pt-4 pb-4 space-y-4;
}

main .panel, input[type="text"], textarea{
  @apply sm:rounded-md;
}

.creator-container {
  @apply flex-col sm:flex-row-reverse space-y-1 sm:space-y-0 content-center;
}

.menu-page.selected {
  @apply h-3/4 sm:h-full overflow-scroll sm:overflow-auto;
}

.creator-view {
  @apply sm:w-1/3;
}

.creator-menu {
  @apply sm:w-2/3 h-1/2 sm:h-full mr-2 p-0;
  padding: 0 !important;
}

legend {
  @apply sr-only;
}

.menu-page, .subpage {
  @apply rounded-b-md;
}

svg {
  @apply bg-white;
}

#portrait-container svg {
  @apply self-center;
  max-width:400px;
  max-height:400px;
}

#portrait-container {
  @apply self-center max-h-full sm:sticky sm:top-4;
}

button, .button-secondary, [role="tab"], nav li:not(.menu-accordion), nav, .subpage-section, .subpage, .feature-card, .cta, input[type="text"], textarea, .palette-editor, .palette-editor .control-container, .menu-description, .control-container .pagination ul, .page-desc,
.page-body, .accordion-header, .accordion-content, fieldset {
  @apply p-2;
}

.panel {
  @apply p-3;
}

button, .cta, .button {
  @apply pl-4 pr-4;
}

.nav-secondary {
  @apply w-1/3;
}

.panel-body {
  @apply w-2/3;
}

.nav-secondary li:not(:last-of-type), .control-container .header, .control-container fieldset ul, .feature-img {
  @apply mb-2;
}

.pagination ul {
  @apply space-x-2;
}

button, nav li, label, .cta {
  @apply text-lg;
}

.button-primary, .cta,
li:not(.control-label).selected {
  @apply bg-primary dark:bg-primary-dark dark:text-black;
}

.button-primary:hover, .cta:hover {
  @apply bg-primary-light;
}

.control-container .header {
  @apply mt-4;
}

.menu-description {
  @apply font-normal;
}


.control-selected {
  @apply p-1 h-4 w-4 float-right relative;
  margin-top: -1.3rem;
  margin-right: 0.3rem;
  font-size: 0.8rem;
}


.cta-panel {
  @apply flex-nowrap;
}

.cta-panel-alt {
  justify-content: space-around !important;
}

.cta-text {
  @apply sm:w-2/3;
}

.cta {
  @apply justify-items-center text-xl m-2;
}

.control-container fieldset ul {
  @apply mt-2;
}

.control-container .pagination ul {
  @apply grid-cols-6;
}

li, .pagination ul, button, .actions label, .feature-card, .feature-img, .cta, .slider,
.slider::-webkit-slider-thumb,
.control-selected, .accordion-header, .control-label svg {
  @apply rounded-md;
}

.pagination li {
  @apply w-12;
}

.feature-card {
  max-width: 200px;
  @apply m-2;
}

.control-label:hover,
.control-label[data-selected="true"], .button-secondary, .actions button, .actions label {
@apply border-secondary
}

.button-secondary {
  @apply bg-transparent;
}

.control-selected, .button-toggle[data-checked="true"], .actions button:hover, .actions label:hover, .button-secondary:hover, .slider::-webkit-slider-thumb,
.slider::-moz-range-thumb {
  @apply bg-secondary dark:text-black;
}

.palette-meta, .palette-actions {
  @apply md:flex-row space-y-2 sm:space-y-0 space-x-0 sm:space-x-2;
}

.palette-meta label, .palette-actions button{
  @apply grow;
}

.control-label:hover,
.actions button:hover,
.actions label:hover,
.button-secondary:hover,
header li:hover,
nav li:not(.menu-accordion):hover,
.pagination li:hover,
.accordion-header:hover,
.cta:hover {
  @apply transition ease-in duration-200
}

/**********************************************************************************************************
Character Creator
**********************************************************************************************************/

.creator-menu-col {
  @apply sm:flex-1 ml-0 sm:ml-2;
}

/*********************************************************************************
Character view 
*********************************************************************************/
.creator-view {
  @apply sm:w-1/3;
}

#portrait-container {
  @apply sm:sticky sm:top-2;
}

.creator-menu-col {
  @apply overflow-scroll sm:w-2/3 sm:max-h-full sm:overflow-auto;
}

/*********************************************************************************
Menu Navigation Tabs
*********************************************************************************/

.nav-inline li img {
  @apply h-4 w-4 mr-2;
}

/*********************************************************************************
page
*********************************************************************************/
.menu-page {
  @apply rounded-t-none !important;
}

/*********************************************************************************
Controls
*********************************************************************************/

.page-input-section {
  @apply p-4;
}

.subpage-col {
  @apply flex-1;
}

.slider::-webkit-slider-thumb,
.slider::-moz-range-thumb, button,
nav li,
label,
.cta {
  @apply cursor-pointer;
}

.control-label {
  @apply flex-initial m-1;
}

header li:not(.button-primary):hover, nav li:not(.menu-accordion):hover, .pagination li:hover, .accordion-header:hover {
  @apply border-primary dark:border-primary-dark ;
}

.control-label {
  @apply shadow;
}

.control-overlay.selected {
  @apply p-1 rounded-2xl h-4 w-4 float-right relative;
  margin-top: -1.3rem;
  margin-right: 0.3rem;
}

.actions label, .actions input, .actions button, .dependency-message, .control-overlay.selected, .pagination li {
  @apply text-sm;
}

.slider {
  -webkit-appearance: none;
  @apply bg-neutral dark:bg-neutral-dark h-4 outline-none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  @apply opacity-100;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply appearance-none;
}

.slider::-webkit-slider-thumb,.slider::-moz-range-thumb {
  @apply w-7 h-7 border-0
}
